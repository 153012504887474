.transition {
    overflow: hidden;
    height: 0rem;
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;

}

.transition.active {
    height: 100%;
    transition: all 0.25s ease-out;
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;
}